@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~shards-ui/dist/css/shards.min.css";

.App {
  text-align: center;
}

h2 {
  margin-top: 20px;
  font-size: 32px;
}
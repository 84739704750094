div.login {
    min-height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;

    h1 {
        margin-top: -12px;
    }

    input {
        margin-top: 16px;
    }
}